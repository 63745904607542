import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

const ConfirmDialog = ({
  isOpen,
  setIsOpen,
  handleClose,
  handleYes,
  title,
  text,
}) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={disabled} onClick={handleClose}>
          close
        </Button>
        <Button
          disabled={disabled}
          autoFocus
          onClick={() => {
            setDisabled(true);
            handleYes();
            setIsOpen(false);
            setDisabled(false);
          }}
        >
          okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
