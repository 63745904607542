// use this instead of the IonHeader for consistency

import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from "@ionic/react";
import { Container, Typography } from "@mui/material";
import React from "react";

const PageHeader = ({ title, children, color }) => {
  return (
    <IonHeader>
      <IonToolbar color={color ? color : "dark"}>
        <IonButtons style={{ position: "absolute", top: 3 }}>
          <IonMenuButton />
        </IonButtons>
        {title ? (
          <Typography sx={{ textAlign: "center" }} variant="h5">
            {title}
          </Typography>
        ) : (
          ""
        )}
        {children}
      </IonToolbar>
    </IonHeader>
  );
};

export default PageHeader;
