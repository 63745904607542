// matches the backend api routes with easier access incase the root changes

import axios from "axios";

let baseURL;
if (process.env.NODE_ENV === "production") {
  baseURL = "https://icea-backend-dyjrcm4pzq-uw.a.run.app/";
} else {
  // baseURL = "https://icea-backend-dyjrcm4pzq-uw.a.run.app/";
  baseURL = "http://localhost:8000/";
}
console.log("node env: ", process.env.NODE_ENV);
// console.log("base url: ", baseURL);

const root = axios.create({
  baseURL,
  timeout: 1000,
});

export const users = axios.create({
  baseURL: baseURL + "users",
});

export const authenticated = (token) => {
  return axios.create({
    baseURL,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const tournaments = axios.create({
  baseURL: baseURL + "tournament",
});

export const child = (token) => {
  const obj = axios.create({
    baseURL: baseURL + "child",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return obj;
};

export default root;

// function combineURLs(baseURL, relativeURL) {
//   return relativeURL
//     ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
//     : baseURL;
// }
