const primaryBackendURL = process.env.NODE_ENV === "production" ? "https://icea-backend-dyjrcm4pzq-uw.a.run.app/" : "http://localhost:8000";

const config = {
    tournament: {
        createTournament: {
            url: primaryBackendURL + "/tournament/createtournamentswithsections",
            method: "POST"
        },
        fetchActiveTournaments: primaryBackendURL + "/tournament/active/",
        fetchTournaments: primaryBackendURL + "/tournament/",
        updateTournament: {
            method: "PUT",
            url: primaryBackendURL + "/tournament/",
        },
        deleteTournament: {
            method: "DELETE",
            url: primaryBackendURL + "/tournament/",
        },
        getSection: {
            method: "GET",
            url: primaryBackendURL + "/tournament/sections/"
        },
        publish: {
            method: "PUT",
            url: primaryBackendURL + "/tournament/publish/"
        },
        unpublish: {
            method: "PUT",
            url: primaryBackendURL + "/tournament/unpublish/"
        }
    },

    booking: {
        checkout: primaryBackendURL + "/checkout/"
    },

    children: {
        getChildrenByParentId: primaryBackendURL + "/child/getByParentId/",
    }
}

export default config;