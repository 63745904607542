import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticate } from "../actions/User";
import { Box } from "@mui/system";
import { Field, Form } from "react-final-form";
import {
  Avatar,
  Button,
  Grid,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { InputOutlined } from "../components/Inputs";
import isEmail from "validator/lib/isEmail";
import PageHeader from "../layouts/PageHeader";

function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}
const Login = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [snackMessage, setSnackMessage] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();

  const handleSnackClose = () => {
    setOpen(false);
    setSeverity("");
    setSnackMessage("");
  };

  return (
    <IonPage>
      <PageHeader title={"Login"} />

      <IonContent fullscreen className="page">
        <Form
          onSubmit={(vals) =>
            dispatch(
              authenticate(vals, (message, successful) => {
                if (!successful) {
                  setOpen(true);
                  setSeverity("error");
                  setSnackMessage(message);
                  return;
                } else if (/* if there is a redirectTo param */ false) {
                } else {
                  history.push("/profile");
                }
              })
            )
          }
          render={({ handleSubmit }) => {
            return (
              <Box
                sx={{
                  marginTop: 8,
                  marginX: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",

                  backgroundColor: "white",
                  borderRadius: 2,
                  padding: 2,
                  width: "fit-content",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>

                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <Field
                    component={InputOutlined}
                    margin="normal"
                    validate={(v) =>
                      v
                        ? isEmail(v)
                          ? undefined
                          : "Must be a valid email"
                        : "Required"
                    }
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="username"
                    autoComplete="email"
                    autoFocus
                  />
                  <Field
                    component={InputOutlined}
                    margin="normal"
                    validate={(v) => (v ? undefined : "Required")}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    {/* <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid> */}
                    <Grid item>
                      <Link to={`/register`}>
                        Do not have an account? Register
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            );
          }}
        />

        <Snackbar
          open={open}
          onClose={handleSnackClose}
          autoHideDuration={6000}
          action={
            <IconButton size="small" onClick={handleSnackClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <Alert severity={severity}>{snackMessage}</Alert>
        </Snackbar>

        {/* <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass="my-custom-class"
                header={"Error!"}
                message={message}
                buttons={["Dismiss"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon
                style={{ fontSize: "70px", color: "#0040ff" }}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Email or Username</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  autocomplete="off"
                  onIonChange={(e) => setEmail(e.detail.value!)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating"> Password</IonLabel>
                <IonInput
                  type="password"
                  autocomplete="off"
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value!)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                style={{
                  marginTop: "20px",
                }}
                expand="block"
                onClick={handleLogin}
              >
                Login
              </IonButton>
              <p style={{ fontSize: "medium" }}>
                Don't have an account? <Link to="/register">Sign up!</Link>
              </p>
            </IonCol>
          </IonRow>
        </IonGrid> */}
      </IonContent>
    </IonPage>
  );
};

export default Login;
