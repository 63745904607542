import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

export default function StaticDatePickerDemo({ pickedDate, setPickedDate }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        label={`select date`.toUpperCase()}
        displayStaticWrapperAs="mobile"
        openTo="day"
        minDate={new Date("2000-1-2")}
        value={pickedDate}
        onChange={(newValue) => {
          setPickedDate(newValue);
        }}
        // renderInput={(params) => <TextField label="select date" {...params} />}
        showDaysOutsideCurrentMonth
      />
    </LocalizationProvider>
  );
}
