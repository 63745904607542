import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  Alert,
  Avatar,
  ButtonGroup,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadChildren, logout, updateUser } from "../actions/User";
import ChildCard from "../components/Children/ChildCard";

import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import "./styles/Profile.css";
import { Field, Form } from "react-final-form";
import { InputMUI } from "../components/Inputs";
import NewChildCards from "../components/Children/NewChildCards";
import { Box } from "@mui/system";
import PageHeader from "../layouts/PageHeader";

const validation = (vals) => {
  const errors = {};
  return errors;
};

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const children = useSelector((state) => state.users.children);
  const profile = useSelector((state) => state.users.profile);

  const [activeChildMenu, setActiveChildMenu] = useState(null);
  const [editProfileOn, setEditProfileOn] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageError, setMessageError] = useState("");

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    dispatch(loadChildren());
  }, []);

  return (
    <IonPage>
      <PageHeader title={"Edit My Profile"}>
        <IonButton
          slot="end"
          color="danger"
          style={{ marginRight: 10 }}
          onClick={() => {
            dispatch(
              logout((success) => {
                if (!success) {
                  console.log("Logout not successful!");
                  return;
                }
                history.push("/login");
              })
            );
          }}
        >
          Logout
        </IonButton>
      </PageHeader>
      {/* <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="page-title">Profile</IonTitle>
          
        </IonToolbar>
      </IonHeader> */}

      <IonContent>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleSnackBarClose}
        >
          {messageError ? (
            <Alert sx={{ width: "100%" }} severity="error">
              {messageError}
            </Alert>
          ) : (
            <Alert sx={{ width: "100%" }} severity="success">
              Profile Update was Successful
            </Alert>
          )}
        </Snackbar>

        {/* <IonTitle>Hello, {profile.firstname}</IonTitle> */}
        <IonGrid>
          <IonRow>
            <IonCol style={{}} size="6">
              <Form
                onSubmit={(vals) =>
                  dispatch(
                    updateUser(vals, (wasSuccessful) => {
                      if (wasSuccessful) {
                        setMessageError("");
                        setOpenSnackBar(true);
                        setEditProfileOn(false);
                      } else {
                        setMessageError("Profile update failed");
                        setOpenSnackBar(true);
                      }
                    })
                  )
                }
                validate={validation}
                render={({ handleSubmit, values, submitting }) => (
                  <form
                    style={{ marginTop: 20, paddingLeft: 15 }}
                    onSubmit={handleSubmit}
                  >
                    {editProfileOn ? (
                      <ButtonGroup
                        style={{
                          position: "absolute",
                          right: "7%",
                        }}
                        orientation="vertical"
                      >
                        <IconButton type="submit">
                          <DoneIcon />
                        </IconButton>
                        <IconButton onClick={(e) => setEditProfileOn(false)}>
                          <CloseIcon />
                        </IconButton>
                      </ButtonGroup>
                    ) : (
                      <IconButton
                        style={{
                          position: "absolute",
                          right: "7%",
                        }}
                        color="inherit"
                        onClick={(e) => setEditProfileOn(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}

                    <img
                      className="p-img"
                      src="https://previews.123rf.com/images/apoev/apoev1806/apoev180600048/103284647-default-placeholder-businessman-half-length-portrait-photo-avatar-man-gray-color.jpg"
                    />

                    {editProfileOn ? (
                      <div style={{ position: "relative" }}>
                        <IonGrid style={{ padding: 10 }}>
                          <IonRow>
                            <IonCol>
                              <Field
                                name="firstname"
                                label="First Name"
                                initialValue={profile.firstname}
                                component={InputMUI}
                              />
                            </IonCol>

                            <IonCol>
                              <Field
                                name="lastname"
                                label="Last Name"
                                initialValue={profile.lastname}
                                component={InputMUI}
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    ) : (
                      <Typography align="center" variant="h5">
                        {profile.firstname} {profile.lastname}
                      </Typography>
                    )}

                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <MailIcon />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText primary={profile.email} />

                        {/* {editProfileOn ? (
                          <Field
                            name="email"
                            label="Email"
                            initialValue={profile.email}
                            component={InputMUI}
                          />
                        ) : (
                          <ListItemText primary={profile.email} />
                        )} */}
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText primary={profile.username} />

                        {/* {editProfileOn ? (
                          <Field
                            name="username"
                            label="Username"
                            initialValue={profile.username}
                            component={InputMUI}
                          />
                        ) : (
                          <ListItemText primary={profile.username} />
                        )} */}
                      </ListItem>
                      <Typography align="right" variant="body2">
                        Member Since:{" "}
                        {new Date(profile.updatedAt).toDateString()}
                      </Typography>
                    </List>
                  </form>
                )}
              />
            </IonCol>

            <IonCol size="5" offsetSm="1">
              <Typography mt={3} align="center" variant="h5">
                My Children
              </Typography>

              <Box
                display={"flex"}
                flexDirection="column"
                alignItems={"center"}
              >
                <Box>
                  {children ? (
                    children.map((c) => (
                      <ChildCard
                        key={c._id}
                        setOpen={setActiveChildMenu}
                        open={activeChildMenu === c._id}
                        _id={c._id}
                        firstname={c.firstname}
                        lastname={c.lastname}
                        uscfid={c.uscfid}
                        email={c.email}
                        gender={c.gender}
                        birthday={c.birthday}
                      />
                    ))
                  ) : (
                    <Container sx={{ mt: 3 }}>
                      <Typography variant="h6">
                        You can add children under this user account and
                        register tournaments for the children you added too.
                      </Typography>
                      <Typography sx={{ mt: 3 }} variant="body2">
                        Click + below to add your first child
                      </Typography>
                    </Container>
                  )}
                </Box>
                <Box sx={{ mt: 2, mb: 5 }}>
                  <NewChildCards />
                </Box>
              </Box>
              {/* <Button
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                size="large"
                color="primary"
                fullWidth
              >
                add a child
              </Button>
              <ChildMenuForm anchorEl={anchorEl} setAnchorEl={setAnchorEl} /> */}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
