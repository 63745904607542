import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useState } from "react";
import { checkAuthentication } from "../../actions/User";

// High order component to restrict users only if authenticated
// if not, auto redirect to login
export default (Component) => {
  return (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const token = useSelector((state) => {
      return state.users.profile.token;
    });

    useEffect(() => {
      dispatch(
        checkAuthentication((isLoggedIn) => {
          if (!isLoggedIn) history.push("/login");
        })
      );
    }, [token]);

    return <Component {...props} />;
  };
};

// this one does not redirect
// but only adds an attribute to the component passed in
// saying whether the user is logged in: no redirect happens
export const isLoggedIn = (Component) => {
  return (props) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => {
      return state.users.profile.token;
    });

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
      dispatch(checkAuthentication((isLoggedIn) => setIsLoggedIn(isLoggedIn)));
    }, [token]);

    return <Component isLoggedIn={isLoggedIn} {...props} />;
  };
};
