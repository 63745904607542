import { Grid } from "@mui/material";
import { Field } from "react-final-form";
import { InputOutlined, InputSelect } from "../Inputs";

export const fieldSX = {
  width: "100%",
};

// const isEmail = (value) => {
//   return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
//     ? "Invalid email address"
//     : required(value);
// };
const required = (value) => (value ? undefined : "Required");

const TournamentInformations = () => {
  return (
    <Grid container>
      <Field
        name="sections"
        component={InputSelect}
        label="Choose your playing section"
        sx={{ ...fieldSX, marginTop: 3 }}
        validate={required}
        required
        options={[
          // TODO: get the actual sections from the tournament
          { value: "A", label: "US Open" },
          { value: "B", label: "Under 2200" },
          { value: "C", label: "Under 1600" },
          { value: "D", label: "Under 1200" },
        ]}
      />
      <Field
        name="comments"
        component={InputOutlined}
        label="Additional Comments"
        fullWidth
        multiline
        rows={4}
        sx={{ ...fieldSX, marginTop: 2 }}
      />
      {/* <FieldArray
        name="byes"
        component={CheckboxMUI}
        label="Select Byes (Up to 2)"
        sx={fieldSX}
        options={[
          // TODO: get the actual rounds from the tournament
          "Round 1",
          "Round 2",
          "Round 3",
          "Round 4",
          "Round 5",
          "Round 6",
          "Round 7",
          "Round 8",
        ]}
      /> */}
    </Grid>
  );
};

export default TournamentInformations;
