import { AnyAction } from "redux";
import _ from "lodash";

const initProfile: any = () => {
  const profile = localStorage.getItem("profile");
  console.log(profile);

  if (!profile) return {};
  else return JSON.parse(profile);
};

interface Child {
  firstname: string;
  lastname: string;
  uscfid: string;
  parentid: string;
  gender: string;
  email: string;
  birthday: string;
  usermeta: JSON[];
  _id: string;
}

const initialState = {
  profile: initProfile(),
  token: localStorage.getItem("token"),
  children: [] as Child[],
};

export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case NEW_USER_PROFILE:
      return { ...state, profile: payload };

    case TOKEN:
      return { ...state, token: payload };

    case REMOVE_TOKEN:
      return { ...state, token: "" };

    case REMOVE_PROFILE:
      return { ...state, profile: {} };

    case ADD_CHILDREN:
      payload.birthday = new Date(payload.birthday);
      return {
        ...state,
        children: [...state.children, payload],
      };

    case REMOVE_CHILD:
      let children: Child[];
      if (state.children.length > 1)
        children = state.children.filter((c) => c._id !== payload);
      else children = [];
      return {
        ...state,
        children,
      };

    case REMOVE_ALL_CHILDREN:
      return {
        ...state,
        children: [],
      };

    default:
      return state;
  }
};

export const NEW_USER_PROFILE = "NEW_USER_PROFILE";
export const TOKEN = "TOKEN";
export const ADD_CHILDREN = "ADD_CHILDREN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const REMOVE_PROFILE = "REMOVE_PROFILE";
export const REMOVE_CHILD = "REMOVE_CHILD";
export const REMOVE_ALL_CHILDREN = "REMOVE_ALL_CHILDREN";
