import { Grid, Typography } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import { InputOutlined } from "../Inputs";

export const fieldSX = {
  width: "100%",
  marginY: 1,
};

const isEmail = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : required(value);
};
const required = (value) => (value ? undefined : "Required");

const BasicContactsParent = () => {
  return (
    <Grid
      container
      // sx={{ backgroundColor: "#E7DFC6", padding: 2 }}
    >
      <Typography variant="h5" align="center">
        For the Parent:
      </Typography>
      <Grid item xs={6}>
        <Field
          name="Parent First Name"
          component={InputOutlined}
          label="Parent First Name"
          sx={{ ...fieldSX }}
          autoFocus
          validate={required}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="Parent Last Name"
          component={InputOutlined}
          label="Parent Last Name"
          sx={fieldSX}
          validate={required}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="Parent Email"
          component={InputOutlined}
          label="Parent Email"
          sx={fieldSX}
          validate={isEmail}
          required
        />
      </Grid>
    </Grid>
  );
};

export default BasicContactsParent;
