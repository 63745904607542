import {
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonModal,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  Alert,
  Avatar,
  Button,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";

import React, { useContext, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import validator from "validator";
import isEmail from "validator/lib/isEmail";
import { registerUser } from "../actions/User";
import { InputOutlined } from "../components/Inputs";
import { capitalize } from "../data/helperFunctions";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import "./styles/Register.css";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import PageHeader from "../layouts/PageHeader";

const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const required = (value) => (value ? undefined : "Required");
const email = (value) =>
  isEmail(value) ? undefined : "Must enter a valid email";
const pwd = (val) => {
  return validator.isStrongPassword(val, {
    minSymbols: 0,
    // returnScore: true,
  })
    ? undefined
    : "Must contain an upper case, lower case, a number, and longer than 8 characters!";
};

const formValidation = (values) => {
  const errors = {};

  if (values.password !== values.password2) {
    errors.password2 = "Passwords must match";
  }

  return errors;
};

const Register = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();

  const handleSnackClose = () => {
    setOpen(false);
    setSnackMessage("");
  };

  return (
    <IonPage>
      <PageHeader title="Register" />

      <IonContent fullscreen className="page ion-padding ion-text-center">
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert severity="error">{snackMessage}</Alert>
        </Snackbar>

        <CssBaseline />
        <Form
          onSubmit={(vals) => {
            // smoothen out the data (like making first last name capitalized, email lower case...)
            vals.firstname = capitalize(vals.firstname);
            vals.lastname = capitalize(vals.lastname);
            vals.email = vals.email.toLowerCase();

            dispatch(
              registerUser(vals, (resp, successful) => {
                if (!successful) {
                  setModalOpen(true);
                  setModalText("Try using another username or email");
                  return;
                }

                console.log("Registration successful! ", resp);

                // do any additional renderings after API request
                // first, check if successful registration
                // then, redirect back to the page if redirected to this page
                // lastly, ask to add children or change the profile
                history.push("/profile");
              })
            );
          }}
          validate={formValidation}
          render={({ handleSubmit, pristine, form, submitting }) => (
            <Box
              sx={{
                marginTop: 8,
                backgroundColor: "white",
                padding: 2,
                borderRadius: 2,
                width: "fit-content",
                margin: "auto",
              }}
              component="form"
              onSubmit={handleSubmit}
            >
              {/* <IonTitle class="form-title">Sign Up for ICEA</IonTitle> */}
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Grid container sx={{ width: 350, mt: 1, mb: 2 }} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      validate={required}
                      name="firstname"
                      label="First Name"
                      component={InputOutlined}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      validate={required}
                      name="lastname"
                      label="Last Name"
                      component={InputOutlined}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      validate={composeValidators(required, email)}
                      name="email"
                      label="Email"
                      component={InputOutlined}
                      fullWidth
                      inputClass="form-input"
                      itemClass="form-input-item"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      validate={required}
                      name="username"
                      label="Username"
                      component={InputOutlined}
                      fullWidth
                      inputClass="form-input"
                      itemClass="form-input-item"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      validate={composeValidators(required, pwd)}
                      name="password"
                      label="Password"
                      component={InputOutlined}
                      fullWidth
                      type="password"
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Field
                      validate={composeValidators(required, pwd)}
                      name="password2"
                      label="Confirm Password"
                      component={InputOutlined}
                      type="password"
                      fullWidth
                    />
                  </Grid>

                  <IonNote class="form-note">
                    By clicking to register, you agree to{" "}
                    <a
                      href="https://intlcea.org/icea-terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms of service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://intlcea.org/icea-privacy-statement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy statement
                    </a>
                    .
                    {/* in the future, do either (or even both): a learn more component, or a redirected page explaining "all you need to know" */}
                  </IonNote>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={submitting}
                    >
                      Register
                    </Button>
                  </Grid>
                </Grid>

                {/* <IonButton
                  className="form-submit"
                  expand="block"
                  type="submit"
                  disabled={submitting}
                >
                  Register
                </IonButton> */}
                <Grid container justifyContent={"flex-end"}>
                  <Grid item>
                    <Link to="/login">
                      <Typography variant="body2">
                        Already reigstered? Login
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        />
      </IonContent>
    </IonPage>
  );
};

export default Register;
