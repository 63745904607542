import {
  DISPLAYED_TOURNAMENT,
  NEW_ACTIVE_TOURNAMENT,
} from "../reducers/Tournaments";
import api from "./APIs";

export const fetchActiveTournaments = (cb) => async (dispatch, getState) => {
  // const token = getState().users.token

  api
    .get("/tournament/active")
    .then((resp) => {
      console.log(resp);
      dispatch({ type: NEW_ACTIVE_TOURNAMENT, payload: resp.data });
      dispatch({
        type: DISPLAYED_TOURNAMENT,
        payload: determineDisplay(resp.data),
      });
      cb(true, resp);
    })
    .catch((e) => {
      cb(false, e);
      console.log("fetchActiveTournaments error: ", e);
    });
};

const determineDisplay = (tournaments) => {
  let display = tournaments[0];
  tournaments.forEach((tournament) => {
    if (new Date(tournaments.start_time) < new Date(display.start_time)) {
      display = tournament;
    }
  });
  return display;
};
