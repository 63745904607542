import { IonContent, IonPage, IonInput, IonCheckbox, IonSelect, IonSelectOption, IonRadio, IonButton, IonLabel } from "@ionic/react";
import "./styles/Payment.css";

const PaymentSuccessful = () => {

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="div-content-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="148" height="148" viewBox="0 0 148 148">
                        <g id="Group_879" data-name="Group 879" transform="translate(-106 -88)">
                            <g id="Ellipse_135" data-name="Ellipse 135" transform="translate(118 100)" fill="#fff" stroke="#f8f8f8" stroke-width="12">
                            <circle cx="62" cy="62" r="62" stroke="none"/>
                            <circle cx="62" cy="62" r="68" fill="none"/>
                            </g>
                            <g id="Group_877" data-name="Group 877" transform="translate(-8472 -2592)">
                            <line id="Line_1" data-name="Line 1" x2="32" y2="32" transform="translate(8636.5 2738.5)" fill="none" stroke="#ff6060" stroke-linecap="round" stroke-width="10"/>
                            <line id="Line_2" data-name="Line 2" x1="32" y2="32" transform="translate(8636.5 2738.5)" fill="none" stroke="#ff6060" stroke-linecap="round" stroke-width="10"/>
                            </g>
                        </g>
                        </svg>
                    <p className="p-payment-message"> Payment Failed. Please Try Again. </p>
                    <IonButton onClick={() => {
                        window.location.href = "./";
                    }}> Return to Home </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
    
}

export default PaymentSuccessful;