import {
  IonButton,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  useIonPicker,
} from "@ionic/react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { FieldRenderProps } from "react-final-form";
import "./styles/Inputs.css";

// export const CheckBox = ({
//   input,
//   meta,
//   label,
//   ...rest
// }: FieldRenderProps<boolean, any>) => {
//   return <FormControlLabel></FormControlLabel>;
// };

export const CheckboxMUI = ({
  input,
  meta,
  label,
  fields,
  options,
  ...rest
}: FieldRenderProps<boolean, any>) => {
  const toggle = (event, option) => {
    if (event.target.checked) {
      fields.push(option);
    } else fields.remove(option);
  };

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      {options.map((option) => (
        <FormControlLabel
          control={
            <Checkbox
              value={option}
              onChange={(event) => toggle(event, option)}
            />
          }
          label={option}
        />
      ))}
    </FormGroup>
  );
};

export const InputSelect = ({
  input,
  meta,
  label,
  ...rest
}: FieldRenderProps<string, any>) => {
  return (
    <FormControl sx={rest.sx}>
      <FormLabel>{label}</FormLabel>
      <Select {...input} {...rest}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {rest.options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export const CheckBoxIonic: React.FC<InputProps> = ({
  input,
  meta,
  ...rest
}) => {
  return (
    <IonItem>
      <IonCheckbox checked />
      <IonLabel>{input.value}</IonLabel>
    </IonItem>
  );
};

type InputProps = FieldRenderProps<string, any>;

export const InputOutlined: React.FC<InputProps> = ({
  input,
  meta,
  ...rest
}) => {
  return (
    <TextField
      {...input}
      {...rest}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : ""}
      InputLabelProps={
        {
          // shrink: true,
        }
      }
      variant={rest.variant ? rest.variant : "outlined"}
    />
  );
};

export const InputMUI: React.FC<InputProps> = ({ input, meta, ...rest }) => {
  return (
    <TextField
      {...input}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : ""}
      label={rest.label}
      InputLabelProps={
        {
          // shrink: true,
        }
      }
      variant="standard"
    />
  );
};

export const InputDate: React.FC<InputProps> = ({ input, meta, ...rest }) => {
  console.log(input.value.toString());
  return (
    <TextField
      {...input}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : ""}
      label={rest.label}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export const Input: React.FC<InputProps> = ({ input, meta, ...rest }) => {
  return (
    <div>
      <IonItem style={{ width: "175px" }} class={rest.itemClass}>
        <IonLabel
          style={{ color: "black" }}
          class="input-label"
          position="floating"
        >
          {rest.label}
        </IonLabel>
        <IonInput
          class={rest.inputClass}
          onIonChange={input.onChange}
          type={rest.inputType}
        />
      </IonItem>
      <p style={{ width: "175px" }} className="error">
        {meta.error && meta.touched && <span>{meta.error}</span>}
      </p>
    </div>
  );
};

export const RadioInput: React.FC<InputProps> = ({ input, meta, ...rest }) => {
  console.log(input.value);
  return (
    <FormControl error={meta.error && meta.touched}>
      <FormLabel>{rest.label}</FormLabel>
      <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>

      <RadioGroup row {...input}>
        {rest.options.map((opt: any, i: number) => {
          return (
            <FormControlLabel
              key={i}
              value={opt.value}
              control={<Radio />}
              label={opt.name}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

type DateProps = FieldRenderProps<string, any>;
export const DateInput: React.FC<DateProps> = ({ input, meta, ...rest }) => {
  return <TextField {...input} type="date" />;
};

// type DateProps = FieldRenderProps<string, any>;
// export const DateTime: React.FC<DateProps> = ({ input, meta, ...rest }) => (
//   <IonItem>
//     <IonLabel>{input.value}</IonLabel>
//     <IonDatetime
//       placeholder={rest.label}
//       class="rt-field rt-datetime"
//       pickerOptions={{
//         buttons: [
//           {
//             text: "Cancel",
//           },
//           {
//             text: "Save",
//             handler: (val) => rest.form.change(input.name, JSON.stringify(val)),
//           },
//         ],
//       }}
//       value={input.value}
//       onIonChange={input.onChange}
//       displayFormat="MMM DD, YYYY"
//       {...rest}
//     ></IonDatetime>
//   </IonItem>
// );

type RoundPickerProps = FieldRenderProps<string, any>;
export const RoundPicker: React.FC<RoundPickerProps> = ({
  input,
  meta,
  ...rest
}) => {
  const [present] = useIonPicker();
  return (
    <IonButton
      onClick={() => {
        present({
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
            },

            {
              text: "Select",
              role: "done",
              handler: (value) => {
                rest.form.change(input.name, JSON.stringify(value));
              },
            },
          ],
          columns: [
            {
              name: "Bye Round 1",
              options: [
                { text: "Round 1", value: "dog" },
                { text: "Round 2", value: "cat" },
                { text: "Round 3", value: "bird" },
                { text: "Round 4", value: "bird" },
              ],
            },
            {
              name: "Bye Round 2",
              options: [
                { text: "Round 1", value: "dog" },
                { text: "Round 2", value: "cat" },
                { text: "Round 3", value: "bird" },
                { text: "Round 4", value: "bird" },
              ],
            },
          ],
        });
      }}
    >
      {rest.placeholder}
    </IonButton>
  );
};
