import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Menu from "./components/Menu";
import routes from "./data/routes";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import React, { useState, useEffect } from 'react';

const App: React.FC = () => {
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    // Ask for password using the prompt method
    const password = prompt('Please enter the password:');

    // Check if the password is correct
    if (password === 'ICEA') {
      setIsAllowed(true);
    }
  }, []);


  if (!isAllowed) {
    return (<p>Check your password</p>); // don't render anything if the password is incorrect
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            {routes.map((route) => (
              <Route
                key={route.url}
                path={route.url}
                component={route.component}
                exact={true}
              />
            ))}
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
