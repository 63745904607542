const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLIENT_SECRET:
      return { ...state, clientSecret: payload };

    default:
      return state;
  }
};

export const CLIENT_SECRET = "CLIENT_SECRET";
