import { Alert, Button, Snackbar } from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { NewChildCard } from "./ChildCard";
import { registerChild } from "../../actions/User";

const CardForm = ({ removeCard, id, submissionSuccess, submissionError }) => {
  const dispatch = useDispatch();

  return (
    <Form
      onSubmit={(vals) =>
        dispatch(
          registerChild(vals, (successful) => {
            if (successful) submissionSuccess();
          })
        )
      }
      render={({ handleSubmit, submitting, values, valid }) => (
        <NewChildCard
          submitForm={() => {
            // when there are no errors according to this final form instance's validations
            // perform the handlesubmit function passed in earlier from the onsubmit prop
            // final forms will do some clean ups so we pass a separate prop
            if (valid) handleSubmit();
            else submissionError();
          }}
          values={values}
          removeCard={removeCard}
          id={id}
        />
      )}
    />
  );
};

const NewChildCards = () => {
  const [id, setId] = useState(0);
  const [activeCards, setActiveCards] = useState([]);

  const [isCardActive, setIsCardActive] = useState(false);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("warning");

  const submissionError = () => {
    setSnackOpen(true);
    setSnackMsg("Child Registration Failed");
    setSnackSeverity("warning");
  };

  return (
    <div>
      {isCardActive ? (
        <CardForm
          submissionError={submissionError}
          submissionSuccess={() => {
            setIsCardActive(false);

            setSnackOpen(true);
            setSnackMsg("Child registration successful!");
            setSnackSeverity("success");
          }}
          removeCard={() => {
            setIsCardActive(false);
          }}
        />
      ) : (
        <Button
          variant="contained"
          fullWidth
          sx={{ width: 325 }}
          size="large"
          onClick={() => {
            // setId((prev) => {
            //   prev++;
            //   return prev;
            // });
            // activeCards.push({ id, CardForm });
            // setActiveCards(activeCards);

            setIsCardActive(true);
          }}
        >
          <AddIcon />
        </Button>
      )}

      <Snackbar
        open={snackOpen}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert severity={snackSeverity}>{snackMsg}</Alert>
      </Snackbar>
    </div>
  );
};

export default NewChildCards;
