import { Button } from "@mui/material";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { generateClientSecret } from "../../actions/Payment";

const StripeCheckout = () => {
  useEffect(() => {
    // ill first need to know the price of all of them added up
    // that requires fetching them from backend
    generateClientSecret();
  }, []);

  const secret = useSelector((state) => state.payment.clientSecret);
  const stripePromise = loadStripe(secret);

  const item = {
    price: "price_1J9Z2pJZ2Z2Z2Z2Z2Z2Z2Z2Z",
    quantity: 1,
  };

  const checkoutOptions = {
    lineItems: [item],
    mode: "payment",
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`,
  };

  const redirectToCheckout = async () => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    if (error) {
      console.log(error);
    }
  };

  return (
    <Elements stripe={stripePromise}>
      {/* now it is the form to check out */}
      <form>
        <PaymentElement />
        <Button>Checkout</Button>
      </form>
    </Elements>
  );
};

export default StripeCheckout;
