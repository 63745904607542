import { IonContent, IonInput, IonPage, IonButton, IonCard, IonDatetime, IonTextarea } from "@ionic/react";
import { useState, ChangeEvent, useEffect } from "react";
import config from "../config/config";
import TournamentItem from "./components/TournamentItem";
import TournamentEditor from "./components/Admin"

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

import { TextField, FormControl, Button, InputAdornment } from '@mui/material';

import "./styles/admin.css";
import ReactMarkdown from "react-markdown";

const AdminListPage = () => {

    const [tournamentList, modTournamentList] = useState([]);
    const [tournamentStatus, modTournamentStatus] = useState({s: "", id: "", n: "", st: "", et: "", dsc:"", sec: {}});
    const [tournamentListWidths, modTournamentListWidths] = useState({
        list: 100,
        panel: 0,
    });

    const [c, mc] = useState({n: true, dsc: true, st: true, et: true});

    const [sectionId, modSectionId] = useState(1);

    useEffect(async () => {
        fetchBackend();
    }, []);

    async function fetchBackend() {
        await fetch(config.tournament.fetchTournaments, {
            method: "GET",
        })
        .then(res => {
            return res.json();
        })
        .then(tournaments => {
            console.log(tournaments)
            modTournamentList( _ => {
                console.log(tournaments)
                return tournaments;
            });
        })
        .catch(e => {
            console.log(e.error);
        });

        modTournamentListWidths( _ => {
            return ({list: 100, panel: 0});
        });
    }

    async function deleteTournament(id) {
        await fetch(config.tournament.deleteTournament.url + id, {
            method: config.tournament.deleteTournament.method,
        });

        fetchBackend();
    }

    async function selectTournament(e, id, i, type) {
        await modTournamentListWidths( _ => {
            return ({list: 40, panel: 60});
        });

        if (type == "NEW") {
            modTournamentList(prev => {
                prev.push({tournament_name: "", start_time: "", end_time: "", description: "", sections: []})
                return prev;
            });
        } else {
            let sections = [];
            let keys = Object.keys(JSON.parse(tournamentList[i].sections));
    
            for (let k = 0; k < keys.length; k++) {
    
                await fetch(config.tournament.getSection.url + keys[k], {
                    method: config.tournament.getSection.method,
                }).then(res => {
                    return res.json();
                }).then(section => {
                    sections.push(section);
                    sections[k]._id = keys[k];
                });
            }

            modTournamentList(prev => {
                prev[i].sections = sections;
                return prev;
            });
        }

        await modTournamentStatus(_ => {
            const r = {s: type, id: id, n: tournamentList[i].tournament_name, st: tournamentList[i].start_time, et: tournamentList[i].end_time, dsc: tournamentList[i].description, sec: tournamentList[i].sections, p: tournamentList[i].published}
            console.log(r)
            return r;
        });
        
    }

    function modTournamentInfo(e, type) {
        const tgt = e.currentTarget;

        modTournamentStatus( prevTournament => {
            let newTournament = {...prevTournament};
            switch(type) {
                case "NAME": 
                    newTournament.n = tgt.value;
                    break;
                case "DESCRIPTION": 
                    newTournament.dsc = tgt.value;
                    break;
                case "START_DATE":
                    newTournament.st = tgt.value;
                    break;
                case "END_DATE":
                    newTournament.et = tgt.value;
                    break;
            }

            return newTournament;
        })

        console.log(c)

        mc(pc => {
            switch(type) {
                case "NAME": 
                    pc.n = false;
                    break;
                case "DESCRIPTION": 
                    pc.dsc = false;
                    break;
                case "START_DATE":
                    pc.st = false;
                    break;
                case "END_DATE":
                    pc.et = false;
                    break;
            }

            return pc;
        });
    }

    function addSection() {
        modSectionId(prevId => {
            return prevId + 1;
        })

        modTournamentStatus(prevStatus => {
            const placeholder = {
                "id": sectionId,
                "name": "",
                "price": 0,
            }

            const newStatus = {...prevStatus}
            newStatus.sec = prevStatus.sec;
            newStatus.sec.push(placeholder);
            return newStatus;
        })
    }

    function modifySection(e, sectionId, type) {
        const tgt = e.currentTarget;
        modTournamentStatus(prevStatus => {
            const newStatus = {...prevStatus};
            newStatus.sec.forEach(section => {
                if (section.id == sectionId) {
                    switch(type) {
                        case "NAME":
                            section.name = tgt.value;
                        break;
                        case "PRICE": 
                            section.price = parseInt(tgt.value) * 100;
                        break;
                    }
                }
            })
            return newStatus;
        })

    }

    function removeSection(sectionId) {

        modTournamentStatus(prevStatus => {
            const newStatus = {...prevStatus};
            var index = 0;
            newStatus.sec.forEach(section => {
                if (section.id == sectionId) {
                    newStatus.sec.splice(index, 1);
                }
                index++;
            })

            return newStatus;
        })
    }

    async function publish() {
        await fetch(config.tournament.publish.url + tournamentStatus.id, {
            method: config.tournament.publish.method,
        }).then(window.location.reload())
    }

    async function unpublish() {
        await fetch(config.tournament.unpublish.url + tournamentStatus.id, {
            method: config.tournament.unpublish.method,
        }).then(window.location.reload())
    }

    async function handleSubmit() {
        console.log("Creating tournament with following info: " + JSON.stringify(tournamentStatus));

        const startDate = new Date(tournamentStatus.st).getTime();
        const endDate = new Date(tournamentStatus.et).getTime();

        if (startDate > endDate) {
            alert("The Start Date cannot be after the End Date!");
            return;
        }

        switch (tournamentStatus.s) {
            case "EDIT": 
                await fetch(config.tournament.updateTournament.url + tournamentStatus.id, {
                    method: config.tournament.updateTournament.method,
                    headers: {
                        "Content-Type": "application/json",
                        },
                    body: JSON.stringify({
                        tournament_name: "" + tournamentStatus.n,
                        description: "" + tournamentStatus.dsc,
                        start_time: "" + tournamentStatus.st,
                        end_time: "" + tournamentStatus.et,
                        sections: tournamentStatus.sec,
                    }),
                })
                .then(res => {
                    return res.json();
                })
                .then(tournament => {
                    console.log(tournament);
                })
                break;
            case "NEW":
                await fetch(config.tournament.createTournament.url, {
                    method: config.tournament.createTournament.method,
                    headers: {
                        "Content-Type": "application/json",
                        },
                    body: JSON.stringify({
                        tournament_name: "" + tournamentStatus.n,
                        description: "" + tournamentStatus.dsc,
                        start_time: "" + tournamentStatus.st,
                        end_time: "" + tournamentStatus.et,
                        sections: tournamentStatus.sec,
                    }),
                })
                .then(res => {
                    return res.json();
                })
                .then(tournament => {
                    console.log(tournament);
                })
                break;
        }

        window.location.reload();
    }

    return (
        <IonPage>
            <IonContent>
                <div className="admin-list-wrapper" style={{width: tournamentListWidths.list + "%"}}>
                    <div className="list-wrapper">
                        <h1 className="header"> All Tournaments </h1>
                        {
                            tournamentList.map((tournament, i) => {
                                return (
                                    <div key={tournament._id}>
                                        <div className="tournament-item-wrapper">
                                            <div className="tournament-divider" />
                                            <div className="tournament-item-content">
                                                <span> {tournament.tournament_name} </span>
                                                <button className="icon">
                                                <DeleteIcon color="error" onClick={function(e) {
                                                    deleteTournament(tournament._id);
                                                }}></DeleteIcon>
                                                </button>
                                                <button className="icon">
                                                <EditIcon color="info" onClick={function(e) {
                                                    selectTournament(e, tournament._id, i, "EDIT");
                                                }}></EditIcon>
                                                </button>
                                                {tournament.published && <div className="icon-static"><CheckCircleIcon color="success"></CheckCircleIcon></div>}
                                                {!tournament.published && <div className="icon-static"><UnpublishedIcon color="action"></UnpublishedIcon></div>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <br></br><br></br>

                        <Button onClick={function(e) {fetchBackend()}} variant="outlined"> Refresh </Button> <br></br><br></br>
                        <Button onClick={function(e) {selectTournament(e, 0, tournamentList.length, "NEW")}} variant="contained"> New Tournament </Button> <br></br><br></br>
                    </div>
                </div>

                <div className="admin-list-wrapper" style={{width: tournamentListWidths.panel + "%"}}>
                    <div className="list-wrapper">
                        {tournamentStatus.s == "EDIT" && <h1 className="header"> Editing: {tournamentStatus.n} </h1>}
                        {tournamentStatus.s == "NEW" && <h1 className="header"> New Tournament: {tournamentStatus.n} </h1>}
                        <FormControl id="wrapper" onSubmit={handleSubmit}>
                            <h5>Title/Name</h5>
                            {(tournamentStatus.s == "NEW" && <TextField required fullWidth variant="filled" onBlur={(e) => {modTournamentInfo(e, "NAME")}} error={!(tournamentStatus.n.length || c.n)} helperText={!(tournamentStatus.n.length || c.n) ? "*Required" : ""} label="Tournament Name"> </TextField>)}
                            {(tournamentStatus.s == "EDIT" && <TextField required fullWidth variant="filled" onBlur={(e) => {modTournamentInfo(e, "NAME")}} error={!(tournamentStatus.n.length)} helperText={!(tournamentStatus.n.length) ? "*Required" : ""} defaultValue={tournamentStatus.n} label="Tournament Name"> </TextField>)}
                            <h5>Description</h5>
                            {(tournamentStatus.s == "NEW" && <TextField required fullWidth multiline className="txt" variant="filled" onBlur={(e) => {modTournamentInfo(e, "DESCRIPTION")}} error={!(tournamentStatus.dsc.length || c.dsc)} helperText={!(tournamentStatus.dsc.length || c.dsc) ? "*Required" : ""} label="Tournament Description"> </TextField> )}
                            {(tournamentStatus.s == "EDIT" && <TextField required fullWidth multiline className="txt" variant="filled" onBlur={(e) => {modTournamentInfo(e, "DESCRIPTION")}} error={!(tournamentStatus.dsc.length)} helperText={!(tournamentStatus.dsc.length) ? "*Required" : ""} defaultValue={tournamentStatus.dsc} label="Tournament Description"> </TextField> )}
                            <h5>Description MarkDown Preview</h5>
                            <div className="div-markdown-container">
                                <ReactMarkdown className="">{tournamentStatus.dsc}</ReactMarkdown>
                            </div>
                            <h5>Scheduled Date</h5>
                            <span> Start Date </span> <TextField variant="filled" onChange={(e) => {modTournamentInfo(e, "START_DATE")}} type="date" value={tournamentStatus.st.toString().substring(0, 10)} error={!(tournamentStatus.st.length || c.st)} helperText={!(tournamentStatus.st.length || c.st) ? "*Required" : ""} shrink={true} hiddenLabel> </TextField> <br></br><br></br>
                            <span> End Date </span> <TextField variant="filled" onChange={(e) => {modTournamentInfo(e, "END_DATE")}} type="date" value={tournamentStatus.et.toString().substring(0, 10)} error={!(tournamentStatus.et.length || c.et)} helperText={!(tournamentStatus.et.length || c.et) ? "*Required" : ""} shrink={true} hiddenLabel> </TextField> <br></br><br></br>
                            <h5>Sections</h5>
                            <Button variant="outlined" onClick={function (e) {
                                addSection()
                            }}> Add Section </Button>
                            {Object.values(tournamentStatus.sec).map((section) => {
                                return (
                                    <IonCard key={section["id"]} className="IonCard-section">
                                        <Button variant="outlined" onClick={() => {removeSection(section["id"])}}> <DeleteIcon color="error"></DeleteIcon> </Button> <br></br><br></br>
                                        <TextField label="Section Name" defaultValue={section["name"]} onBlur={(e) => {modifySection(e, section["id"], "NAME")}}> </TextField> <br></br><br></br>
                                        <TextField label="Section Price" defaultValue={(section["price"] / 100 != 0) ? (section["price"] / 100).toFixed(2) : ''} onBlur={(e) => {modifySection(e, section["id"], "PRICE")}} inputProps={{startAdornment: <InputAdornment position="start"> $ </InputAdornment>}}> </TextField> <br></br><br></br>
                                    </IonCard>
                                )
                            })}

                            <br></br><br></br>

                            {tournamentStatus.p && <Button onClick={unpublish} variant="outlined"> Unpublish </Button>}
                            {!tournamentStatus.p && tournamentStatus.s == "EDIT" && <Button onClick={publish} variant="outlined"> Publish Tournament </Button>}

                            <br></br><br></br>
                            
                            {!(tournamentStatus.n && tournamentStatus.dsc && tournamentStatus.st && tournamentStatus.et && Object.keys(tournamentStatus.sec)).length && <Button type="submit" variant="contained" disabled> Save Tournament </Button>}
                            {tournamentStatus.n && tournamentStatus.dsc && tournamentStatus.st && tournamentStatus.et && Object.keys(tournamentStatus.sec).length && <Button type="submit" variant="contained" onClick={handleSubmit}> Save Tournament </Button>}
                            
                        </FormControl>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default AdminListPage;