import { IonContent, IonGrid, IonPage, IonTitle } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { isLoggedIn } from "../components/HOC/requireAuth";
import Card from "./Card";
import User from "../data/user";
import PageHeader from "../layouts/PageHeader";
import Tournaments from "../data/tournaments";
import "./styles/Explore.css";
import { useDispatch, useSelector } from "react-redux";
import { TOURNAMENT_REGISTERING } from "../reducers/Tournaments";

const ListPage = ({ isLoggedIn }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const tournamentInfo = useSelector((state) => state.tournaments.displayed);
  const tournamentsInfo = useSelector((state) => state.tournaments.active);

  // const [tournamentsInfo, setTournamentsInfo] = useState([]);

  return (
    <IonPage>
      <PageHeader>
        <div id="banner">
          <IonTitle class="subtitle"> USCF Rated ICEA Monthly </IonTitle>
          <IonTitle class="title"> Scholastic Chess Tournament </IonTitle>
          <IonTitle class="text">
            {" "}
            Upcoming Tournament:{" "}
            {new Date(tournamentInfo.start_time).toLocaleDateString()}
          </IonTitle>
          <div className="button-container">
            <button className="banner-buttons"> Entry List </button>

            <button
              className="banner-buttons"
              onClick={() => {
                history.push("./booking");
                dispatch({
                  type: TOURNAMENT_REGISTERING,
                  payload: tournamentInfo,
                });
              }}
            >
              {" "}
              Book This Tournament{" "}
            </button>
          </div>
          <div className="button-container">
            {!isLoggedIn && (
              <p> Please register an account to book tournaments. </p>
            )}
          </div>
        </div>
      </PageHeader>

      <IonContent fullscreen>
        <IonGrid>
          <div id="sections">
            <div className="section" id="standings-section">
              <div className="content-header">
                <h1 className="section-heading"> Standings </h1>
                <div className="separator" />
                <div className="standings-spacer"> </div>
              </div>
              <div id="standings">
                <div id="podium-container">
                  <div className="podium" id="p2nd-place">
                    {" "}
                    <div id="name-container-2">
                      {" "}
                      <div id="spacer-2" />{" "}
                      <h1 className="standings-name"> Bob </h1>{" "}
                    </div>{" "}
                    <h1 className="standings-text" id="s2p">
                      {" "}
                      <br /> 2{" "}
                    </h1>{" "}
                  </div>
                  <div className="podium" id="p1st-place">
                    {" "}
                    <div id="name-container-1">
                      {" "}
                      <div id="spacer-1" />{" "}
                      <h1 className="standings-name"> Alice </h1>{" "}
                    </div>{" "}
                    <h1 className="standings-text" id="s1p">
                      {" "}
                      <br /> 1{" "}
                    </h1>{" "}
                  </div>
                  <div className="podium" id="p3rd-place">
                    {" "}
                    <div id="name-container-3">
                      {" "}
                      <div id="spacer-3" />{" "}
                      <h1 className="standings-name"> Charlie </h1>{" "}
                    </div>{" "}
                    <h1 className="standings-text" id="s3p">
                      {" "}
                      <br /> 3{" "}
                    </h1>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="section" id="previous-events-section">
              <div className="content-header">
                <h1 className="section-heading"> Active Tournaments </h1>
                <div className="separator" />
              </div>
              {tournamentsInfo.map((t, i) => {
                if (tournamentInfo.id == i)
                  return (
                    <Card
                      key={t.createdAt}
                      date={t.start_time.toString().substring(0, 10)}
                      description={t.description}
                      isBook={true}
                    >
                      {" "}
                    </Card>
                  );
                else
                  return (
                    <Card
                      key={t.createdAt}
                      date={t.start_time.toString().substring(0, 10)}
                      description={t.description}
                    >
                      {" "}
                    </Card>
                  );
              })}
            </div>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default isLoggedIn(ListPage);
