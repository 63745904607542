import React from "react";
import "./styles/Explore.css";

const Card = ({date, description, isBook}) => {
    return (
        <div id="previous-events">
            <div className="previous-event-card" id="event-1">
                <div className="card-date">
                <h3 className="card-date"> {date} </h3>
                </div>
                <div className="card-separator" />
                <div className="description">
                {description}
                </div>
                <div className="results-button-container">
                {" "}
                {(isBook && <button className="results-button" onClick={() => {
                    window.location = "localhost:3000/booking"
                }}> Book this Tournament </button>)}
                </div>
                <div className="card-content"></div>
            </div>
        </div>
    )
}

export default Card;