import { listOutline, newspaperOutline } from "ionicons/icons";
import React from "react";
import requireAuth from "../components/HOC/requireAuth";
import Explore from "../pages/Explore";
import Login from "../pages/Login";
import PaymentFailed from "../pages/PaymentFailed";
import PaymentSuccessful from "../pages/PaymentSuccessful";
import Profile from "../pages/Profile";
import Register from "../pages/Register";
import TournamentListPage from "../pages/TournamentListPage";
import AboutUs from "../pages/AboutUs";
import Booking from "../pages/Booking.jsx";

import { isLoggedIn } from "../components/HOC/requireAuth";

interface AppPage {
  url: string;
  iosIcon?: string;
  mdIcon?: string;
  title?: string;
  component: React.ComponentType<any> | any;
  shownInMenu: Boolean;
}

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/",
    iosIcon: newspaperOutline,
    mdIcon: newspaperOutline,
    component: Explore,
    shownInMenu: true,
  },
  {
    url: "/login",
    shownInMenu: false,
    component: Login,
  },
  {
    title: "Register",
    url: "/register",
    shownInMenu: false,
    iosIcon: listOutline,
    mdIcon: listOutline,
    component: Register,
  },
  {
    url: "/profile",
    shownInMenu: false,
    title: "User Profile",
    component: requireAuth(Profile),
  },
  {
    title: "Booking",
    url: "/booking",
    shownInMenu: false,
    iosIcon: listOutline,
    mdIcon: listOutline,
    component: isLoggedIn(Booking),
  },
  {
    title: "PaymentSuccessful",
    url: "/success",
    shownInMenu: false,
    iosIcon: listOutline,
    mdIcon: listOutline,
    component: PaymentSuccessful,
  },
  {
    title: "PaymentFailed",
    url: "/paymentfailed",
    shownInMenu: false,
    iosIcon: listOutline,
    mdIcon: listOutline,
    component: PaymentFailed,
  },
  {
    title: "Tournaments",
    url: "/admin/tournaments",
    shownInMenu: true,
    iosIcon: listOutline,
    mdIcon: listOutline,
    component: TournamentListPage,
  },
  {
    title: "About Us",
    url: "/admin/AboutUs",
    shownInMenu: true,
    iosIcon: listOutline,
    mdIcon: listOutline,
    component: AboutUs,
  }
];

export default appPages;
