import { AnyAction } from "redux";

export interface section {
  id: number;
  description: string;
  price: number;
  startTime: Date;
  endTime: Date;
}

export interface tournament {
  active: any[];
  // sections: section[];
  displayed: any[];
  registering: any[];
}

const initialState: tournament = {
  active: [],
  displayed: [],
  registering: [],
};

const tournamentReducer = (
  state = initialState,
  { type, payload }: AnyAction
) => {
  switch (type) {
    case NEW_TOURNAMENT:
      return { ...state, ...payload };
      break;

    case NEW_ACTIVE_TOURNAMENT:
      return {
        ...state,
        active: payload,
      };

    case NEW_SECTION:
      return {
        ...state,
        sections: payload,
      };

    case DISPLAYED_TOURNAMENT:
      return {
        ...state,
        displayed: payload,
      };

    case TOURNAMENT_REGISTERING:
      return {
        ...state,
        registering: payload,
      };

    default:
      return state;
      break;
  }
};
export default tournamentReducer;

export const NEW_TOURNAMENT = "NEW_TOURNAMENT";
export const NEW_ACTIVE_TOURNAMENT = "NEW_ACTIVE_TOURNAMENT";
export const NEW_SECTION = "NEW_SECTION";
export const DISPLAYED_TOURNAMENT = "DISPLAYED_TOURNAMENT";
export const TOURNAMENT_REGISTERING = "TOURNAMENT_REGISTERING";
