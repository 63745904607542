import { IonContent, IonPage } from "@ionic/react";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import PageHeader from "../layouts/PageHeader";
import arrayMutators from "final-form-arrays";

import BasicContacts from "../components/booking/BasicContacts";
import { Form } from "react-final-form";
import TournamentInformations from "../components/booking/TournamentInformations";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import BasicContactsParent from "../components/booking/BasicContactsParent";

import ReactMarkdown from "react-markdown";
import StripeCheckout from "../components/booking/StripeCheckout";

const Booking2 = ({ isLoggedIn }) => {
  // get the actual tournament to register for
  const tournament = useSelector((state) => state.tournaments.displayed);
  const history = useHistory();
  // console.log(tournament);

  const [containerRef, setContainerRef] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [childChecked, setChildChecked] = useState(true);

  return (
    <IonPage>
      <PageHeader title={"Book a New Tournament"} color="" children={undefined}>
        <Button
          variant="contained"
          color="warning"
          sx={{ position: "absolute", top: 7, right: 15 }}
          onClick={() => {
            history.goBack();
          }}
        >
          Close
        </Button>
      </PageHeader>
      <IonContent>
        <Paper
        // sx={{
        //   // backgroundImage:
        //   // "url(https://c1.wallpaperflare.com/preview/443/824/1021/chess-pawn-king-game.jpg)",
        //   backgroundSize: "cover",
        //   width: "100%",
        //   height: "100%",
        // }}
        >
          <Grid container spacing={1} sx={{}}>
            <Grid item xs={8}>
              <Container
                sx={{
                  // backgroundColor: "rgba(255,255,255,.6)",
                  backgroundColor: "#E9F1F7",
                  width: "95%",
                  marginY: 3,
                  borderRadius: 2,
                  padding: 2,
                  height: "fit-content",
                  position: "relative",
                  transition: "all .3s ease-in-out",
                  // "&:hover": {
                  //   backgroundColor: "rgba(255,255,255,.8)",
                  //   cursor: "pointer",
                  //   transform: "scale(1.02) ",
                  // },
                }}
                ref={containerRef}
              >
                <Typography variant="h5">
                  {tournament.tournament_name}
                </Typography>
                <ReactMarkdown>{tournament.description}</ReactMarkdown>
                {/* <ReactMarkdown source={tournament.description} /> */}

                {/* <Typography variant="body1">
                  From {new Date(tournament.start_time).toLocaleDateString()} to{" "}
                  {new Date(tournament.end_time).toLocaleDateString()}
                </Typography> */}
                <Typography variant="body1">
                  Sections: {JSON.stringify(tournament.sections)}
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={4} sx={{ position: "relative" }}>
              <Container
                sx={{
                  // backgroundColor: "rgba(255,255,255,.6)",
                  backgroundColor: "#E6E6E6",
                  position: "sticky",
                  width: "95%",
                  marginY: 3,
                  borderRadius: 2,
                  padding: 2,
                  height: "fit-content",
                  position: "relative",
                  transition: "all .3s ease-in-out",
                  // "&:hover": {
                  //   backgroundColor: "rgba(255,255,255,.8)",
                  //   cursor: "pointer",
                  //   transform: "scale(1.02) ",
                  // },
                }}
              >
                <Typography variant="h5">Register Here</Typography>
                <Typography variant="body1"></Typography>
                <FormWrapper ref={containerRef} isIn={currentStep == 0}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => setChildChecked(!childChecked)}
                          checked={childChecked}
                        />
                      }
                      label="I am registering for my child"
                    />
                  </FormGroup>
                  {childChecked ? (
                    <>
                      <BasicContacts />
                      <BasicContactsParent />
                    </>
                  ) : (
                    <BasicContacts />
                  )}
                  <Button onClick={() => setCurrentStep(1)}>Next</Button>
                </FormWrapper>
                <FormWrapper ref={containerRef} isIn={currentStep == 1}>
                  <TournamentInformations />
                  <Button onClick={() => setCurrentStep(0)}>Back</Button>
                  <Button onClick={() => setCurrentStep(2)}>Checkout</Button>
                </FormWrapper>
                <FormWrapper ref={containerRef} isIn={currentStep == 2}>
                  <StripeCheckout />
                </FormWrapper>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </IonContent>
    </IonPage>
  );
};

const FormWrapper = ({ ref, isIn, children }) => {
  return (
    <Form
      onSubmit={(vals) => console.log(vals)}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <Slide
          direction="left"
          in={isIn}
          mountOnEnter
          unmountOnExit
          container={ref}
        >
          <form onSubmit={handleSubmit}>{children}</form>
        </Slide>
      )}
    />
  );
};

export default Booking2;
