import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import { useHistory, useLocation } from "react-router-dom";
// import { trophyOutline } from "ionicons/icons";
import "./styles/Menu.css";

import appPages from "../data/routes";

import { useSelector, useStore } from "react-redux";

import Calender from "./Calender";

const Menu: React.FC<any> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const store = useStore();

  const profile = useSelector((state: any) => state.users.profile);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader
            onClick={() => {
              history.push("/");
            }}
          >
            ICEA Chess
          </IonListHeader>

          {profile.firstname ? (
            <IonNote
              className="menu-username"
              onClick={() => {
                history.push("/profile");
              }}
            >
              {profile.firstname} {profile.lastname}
            </IonNote>
          ) : (
            <IonNote
              className="menu-username"
              onClick={() => {
                history.push("/login");
              }}
            >
              Click to Login
            </IonNote>
          )}

          {appPages.map((appPage, index) => {
            if (appPage.shownInMenu) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />

                    <IonLabel style={{ marginLeft: 10 }}>
                      {appPage.title}
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            }
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>{`check my events`.toLocaleUpperCase()}</IonListHeader>
          <Calender />
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
