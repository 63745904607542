import { Grid } from "@mui/material";
import { Field } from "react-final-form";

import { InputOutlined } from "../Inputs";

export const fieldSX = {
  width: "100%",
  marginY: 1,
};

const isEmail = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : required(value);
};
const required = (value) => (value ? undefined : "Required");

const BasicContacts = () => {
  return (
    <Grid container sx={{ marginTop: 0 }}>
      <Grid item xs={12}>
        <Field
          name="email"
          component={InputOutlined}
          label="Email"
          sx={fieldSX}
          validate={isEmail}
          required
          autoFocus
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="Player First Name"
          component={InputOutlined}
          label="Player First Name"
          sx={{ ...fieldSX }}
          validate={required}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="Player Last Name"
          component={InputOutlined}
          label="Player Last Name"
          sx={fieldSX}
          validate={required}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          name="USCF ID"
          component={InputOutlined}
          label="USCF ID"
          sx={fieldSX}
          validate={required}
          required
        />
      </Grid>
    </Grid>
  );
};

export default BasicContacts;
