import {
  IonContent,
  IonPage,
  IonInput,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonRadio,
  IonButton,
  IonLabel,
} from "@ionic/react";
import { useHistory } from "react-router";
import "./styles/Payment.css";

const PaymentSuccessful = () => {
  const router = useHistory();

  async function load() {
    const url = new URL(window.location.toString());
    const params = new URLSearchParams(url.search);
    await fetch(
      "http://localhost:8000/checkout/get_session/" + params.get("session_id"),
      {
        method: "GET",
        // headers: {
        // "Content-Type": "application/json",
        // },
        // body: JSON.stringify({
        //     session_id: params.get('session_id'),
        // }),
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ status }) => {
        console.log(status);
      })
      .catch((e) => {
        console.error(e.error);
      });

    console.log(params.get("session_id"));
  }

  load();

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="div-content-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="148"
            height="148"
            viewBox="0 0 148 148"
          >
            <g
              id="Group_877"
              data-name="Group 877"
              transform="translate(-106 -88)"
            >
              <g
                id="Ellipse_135"
                data-name="Ellipse 135"
                transform="translate(118 100)"
                fill="#fff"
                stroke="#f8f8f8"
                stroke-width="12"
              >
                <circle cx="62" cy="62" r="62" stroke="none" />
                <circle cx="62" cy="62" r="68" fill="none" />
              </g>
              <g id="check" transform="translate(156 137.727)">
                <g
                  id="Group_876"
                  data-name="Group 876"
                  transform="translate(0 5.814)"
                >
                  <path
                    id="Path_4752"
                    data-name="Path 4752"
                    d="M22.18,40.679a5.691,5.691,0,0,1-8.047,0L1.666,28.212a5.69,5.69,0,1,1,8.047-8.046l7.428,7.427a1.441,1.441,0,0,0,2.033,0L39.287,7.48a5.69,5.69,0,1,1,8.047,8.046Z"
                    transform="translate(0 -5.814)"
                    fill="#41bc6e"
                  />
                </g>
              </g>
            </g>
          </svg>
          <p className="p-payment-message"> Payment Successful! </p>
          <IonButton
            onClick={() => {
              router.push("/");
            }}
          >
            Return to Home{" "}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PaymentSuccessful;
