import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Slide,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PickDate from "./PickDate";

import { fetchActiveTournaments } from "../../actions/Tournaments";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const Calender = () => {
  const dispatch = useDispatch();
  const activeTournamentsRedux = useSelector(
    (state) => state.tournaments.active
  );

  console.log(activeTournamentsRedux);

  const [pickedDate, setPickedDate] = React.useState(new Date());
  const [eventsOnPickedDate, setEventsOnPickedDate] = useState([]);

  useEffect(() => {
    console.log("use effect acting");

    dispatch(
      fetchActiveTournaments((successful, resp) => {
        if (successful) {
          console.log(
            "from calendar component: fetchActiveTournaments successful"
          );
          // setActiveTournaments(resp.data);
        } else {
          // setActiveTournaments();
          console.log(
            "from calendar component: fetchActiveTournaments unsuccessful"
          );
        }
      })
    );
  }, []);

  // useEffect(() => {}, [activeTournaments]);

  return (
    <Container>
      <PickDate pickedDate={pickedDate} setPickedDate={setPickedDate} />

      <List>
        <ListSubheader>
          Events for {pickedDate.toLocaleDateString()}
        </ListSubheader>
        {findActiveTournaments(pickedDate, activeTournamentsRedux).map(
          (tournament) => (
            <Tooltip
              placement="right"
              title={tournament.description}
              key={tournament.id}
            >
              <Slide direction="up" in mountOnEnter unmountOnExit>
                <ListItem>
                  <ListItemIcon>
                    <EmojiEventsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={tournament.tournament_name}
                    secondary={`From ${new Date(
                      tournament.start_time
                    ).toLocaleDateString()} to ${new Date(
                      tournament.end_time
                    ).toLocaleDateString()}`}
                  />
                </ListItem>
              </Slide>
            </Tooltip>
          )
        )}
      </List>
    </Container>
  );
};

export default Calender;

const findActiveTournaments = (date, activeTournaments) => {
  return activeTournaments.filter((tournament) => {
    return (
      new Date(tournament.start_time) <= date &&
      new Date(tournament.end_time) >= date
    );
  });
};
