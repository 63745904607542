import {
  Alert,
  Avatar,
  ButtonGroup,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Snackbar,
} from "@mui/material";
import Card from "@mui/material/Card";
import { red, blue } from "@mui/material/colors";
import { useState } from "react";

import WcIcon from "@mui/icons-material/Wc";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import DateRangeIcon from "@mui/icons-material/DateRange";

import { Field, Form } from "react-final-form";
import { InputDate, InputMUI, RadioInput } from "../Inputs";
import { useDispatch } from "react-redux";
import isEmail from "validator/lib/isEmail";
import ConfirmDialog from "../ConfirmDialog";
import { deleteChild, updateChild } from "../../actions/User";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const required = (v) => (v ? undefined : "* Required");

export const NewChildCard = ({
  values,
  submitForm,
  isSubmitting,
  removeCard,
  id,
}) => {
  const firstname = values.firstname;
  const lastname = values.lastname;

  return (
    <Card
      sx={{
        maxWidth: 325,
        boxShadow: 1,
        margin: "10px",
        "&:hover": {
          boxShadow: 5,
          cursor: "pointer",
        },
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: blue[500] }}>
            {firstname == undefined
              ? "J"
              : firstname.substring(0, 1).toUpperCase()}
            {lastname == undefined
              ? "D"
              : lastname.substring(0, 1).toUpperCase()}
          </Avatar>
        }
        title={`${firstname == undefined ? "John" : capitalize(firstname)} ${
          lastname == undefined ? "Doe" : capitalize(lastname)
        }`}
        action={
          <>
            <ButtonGroup
              style={{
                position: "relative",
                right: "7%",
              }}
            >
              <IconButton type="submit" color="success" onClick={submitForm}>
                <DoneIcon />
              </IconButton>

              <IconButton onClick={(e) => removeCard(id)}>
                <CloseIcon />
              </IconButton>
            </ButtonGroup>
          </>
        }
      />

      <CardMedia component="img" />
      <CardContent>
        <List>
          <ListItem>
            <Field
              name="firstname"
              validate={required}
              label="First Name"
              component={InputMUI}
            />
            <Field
              name="lastname"
              label="Last Name"
              validate={required}
              component={InputMUI}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <CardMembershipIcon />
              </Avatar>
            </ListItemAvatar>

            <Field
              name="uscf"
              validate={required}
              label="USCF ID"
              component={InputMUI}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WcIcon />
              </Avatar>
            </ListItemAvatar>

            <Field
              name="gender"
              type="radio"
              validate={required}
              component={RadioInput}
              label="Select gender"
              options={[
                { name: "Male", value: "Male" },
                { name: "Female", value: "Female" },
              ]}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DateRangeIcon />
              </Avatar>
            </ListItemAvatar>

            <Field
              name="birthday"
              component={InputDate}
              type="date"
              validate={required}
              label="Select Birthday"
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <EmailIcon />
              </Avatar>
            </ListItemAvatar>

            <Field
              name="email"
              label="Email"
              validate={(email) =>
                email
                  ? isEmail(email)
                    ? undefined
                    : "Must be a valid email!"
                  : "* Required"
              }
              component={InputMUI}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

const ChildCard = ({
  firstname,
  lastname,
  uscfid,
  email,
  gender,
  birthday,
  open,
  setOpen,
  _id,
  form,
  validate,
}) => {
  const dispatch = useDispatch();

  const [isEditOn, setIsEditOnState] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const setIsEditOn = (state) => {
    setOpen(null);
    setIsEditOnState(state);
  };

  let color;
  if (gender == "f") color = red[500];
  else color = blue[500];

  return (
    <Card
      sx={{
        maxWidth: 325,
        boxShadow: 1,
        margin: "10px",
        "&:hover": {
          boxShadow: 5,
          cursor: "pointer",
        },
      }}
    >
      <CardActionArea
        onClick={() => {
          if (open) setOpen(null);
          else setOpen(_id);
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: color }}>
              {firstname.substring(0, 1)}
              {lastname.substring(0, 1)}
            </Avatar>
          }
          title={`${firstname} ${lastname}`}
          action={
            <>
              {isEditOn ? (
                <ButtonGroup
                  style={{
                    position: "relative",
                    right: "7%",
                  }}
                >
                  <IconButton
                    type="submit"
                    color="success"
                    onClick={(e) => {
                      // submit form here
                      if (validate) {
                        form.submit();
                        setIsEditOn(false);
                      }
                    }}
                  >
                    <DoneIcon />
                  </IconButton>

                  <IconButton onClick={(e) => setIsEditOn(false)}>
                    <CloseIcon />
                  </IconButton>
                </ButtonGroup>
              ) : (
                <>
                  <IconButton
                    style={{
                      position: "relative",
                      right: "7%",
                    }}
                    color="inherit"
                    onClick={(e) => {
                      setIsEditOn(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteConfirmation(!deleteConfirmation);
                    }}
                    color="warning"
                  >
                    <DeleteIcon />

                    <ConfirmDialog
                      isOpen={deleteConfirmation}
                      setIsOpen={() => setDeleteConfirmation(false)}
                      handleClose={() => {
                        setDeleteConfirmation(false);
                      }}
                      handleYes={() => dispatch(deleteChild(_id))}
                      title={`Do you want to delete ${firstname}?`}
                      text={`${firstname} ${lastname} will be deleted permanently. Are you sure you want to remove this child?`}
                    />
                  </IconButton>
                </>
              )}
            </>
          }
        />
      </CardActionArea>

      <Collapse in={isEditOn ? true : open}>
        <CardContent>
          <List>
            {isEditOn ? (
              <ListItem>
                <Field
                  name="firstname"
                  label="First Name"
                  initialValue={firstname}
                  component={InputMUI}
                  validate={required}
                />
                <Field
                  name="lastname"
                  label="Last Name"
                  initialValue={lastname}
                  component={InputMUI}
                  validate={required}
                />
              </ListItem>
            ) : (
              ""
            )}

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CardMembershipIcon />
                </Avatar>
              </ListItemAvatar>

              {isEditOn ? (
                <Field
                  name="uscf"
                  label="USCF ID"
                  initialValue={uscfid}
                  component={InputMUI}
                  validate={required}
                />
              ) : (
                uscfid
              )}
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WcIcon />
                </Avatar>
              </ListItemAvatar>
              {isEditOn ? (
                <Field
                  name="gender"
                  type="radio"
                  component={RadioInput}
                  label="Select gender"
                  initialValue={gender}
                  validate={required}
                  options={[
                    { name: "Male", value: "Male" },
                    { name: "Female", value: "Female" },
                  ]}
                />
              ) : (
                gender
              )}
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <DateRangeIcon />
                </Avatar>
              </ListItemAvatar>

              {isEditOn ? (
                <Field
                  name="birthday"
                  component={InputDate}
                  type="date"
                  initialValue={birthday}
                  validate={required}
                  label="Select Birthday"
                />
              ) : (
                birthday.toDateString()
              )}
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EmailIcon />
                </Avatar>
              </ListItemAvatar>
              {isEditOn ? (
                <Field
                  name="email"
                  label="Email"
                  initialValue={email}
                  component={InputMUI}
                  validate={(email) =>
                    isEmail(email) ? undefined : "Must be a valid email!"
                  }
                />
              ) : (
                email
              )}
            </ListItem>
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const validations = (vals) => {
  const errors = {};
  return errors;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const dispatch = useDispatch();

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setsnackMsg] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("warning");

  return (
    <Form
      onSubmit={(vals) =>
        dispatch(
          updateChild(vals, (successful) => {
            setSnackOpen(true);

            if (successful) {
              setsnackMsg("Child Updated Successfully!");
              setSnackSeverity("success");
            } else {
              setsnackMsg("Child Failed to Update");
              setSnackSeverity("warning");
            }
          })
        )
      }
      // validate={validations}
      render={(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <ChildCard {...formProps} {...props} />
          {/* {JSON.stringify(formProps.values)} */}
          <Snackbar
            open={snackOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={() => setSnackOpen(false)}
          >
            <Alert severity={snackSeverity}>{snackMsg}</Alert>
          </Snackbar>
        </form>
      )}
    />
  );
};
