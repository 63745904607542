import {
  ADD_CHILDREN,
  NEW_USER_PROFILE,
  REMOVE_ALL_CHILDREN,
  REMOVE_CHILD,
  REMOVE_PROFILE,
  REMOVE_TOKEN,
  TOKEN,
} from "../reducers/User";
import { authenticated, child, users } from "./APIs";

const nameFieldsAutoCap = (data) => {
  // takes the "firstname" and "lastname" fields and capitalize it

  data.firstname = capitalize(data.firstname);
  data.lastname = capitalize(data.lastname);

  return data;

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const deleteChild = (id, cb) => async (dispatch, getState) => {
  child("")
    .delete(`${id}`)
    .then((resp) => {
      console.log(resp);
      // dispatch(loadChildren());
      dispatch({ type: REMOVE_CHILD, payload: id });
    })
    .catch((e) =>
      console.log("error in delete child for ", id, "\nerror is: ", e)
    );
};

export const updateChild = (data, cb) => async (dispatch, getState) => {
  nameFieldsAutoCap(data);

  data.parentid = getState().users.profile.id;
  data.usermeta = {
    chess: { uscfid: data.uscf },
  };

  try {
    child("")
      .put(`/${data.parentid}`, data)
      .then((resp) => {
        console.log(resp);
        dispatch({ type: REMOVE_CHILD, payload: data._id });
        dispatch({ type: ADD_CHILDREN, payload: data });
        cb(true);
      });
  } catch (e) {
    console.log("update child", e);
    cb(false);
  }
};

export const loadChildren = (cb) => async (dispatch, getState) => {
  // requests for all the children under this parent account
  const parentid = getState().users.profile.id;

  dispatch({ type: REMOVE_ALL_CHILDREN });

  child("")
    .get(`getByParentId/${parentid}`)
    .then((resp) => {
      resp.data.map((child) => {
        console.log(child);
        if (child.usermeta) {
          child.uscfid = JSON.parse(child.usermeta).chess.uscfid;
        } else {
          child.uscfid = null;
        }
        dispatch({ type: ADD_CHILDREN, payload: nameFieldsAutoCap(child) });
      });
    })
    .catch((e) => console.log("error load children", e));
};

export const registerChild = (data, cb) => async (dispatch, getState) => {
  // adds the child from the newly inputted form information
  // to the backend API and the frontend state

  nameFieldsAutoCap(data);

  data.parentid = getState().users.profile.id;
  const token = getState().users.token;

  console.log(data);
  data.usermeta = {
    chess: { uscfid: data.uscf },
  };

  console.log(data);
  child("")
    .post("create", data)
    .then((resp) => {
      console.log(resp);
      dispatch(loadChildren());
      cb(true);
    })
    .catch((e) => {
      console.log("Error message in register child", e);
      cb(false);
    });
};

export const logout = (cb) => async (dispatch, getState) => {
  dispatch({ type: REMOVE_TOKEN });
  dispatch({ type: REMOVE_PROFILE });
  localStorage.removeItem("token");
  localStorage.removeItem("profile");
  cb(true);
};

export const checkAuthentication = (cb) => async (dispatch, getState) => {
  // checks with endpoint if token is valid
  const token = getState().users.token;

  users
    .get("current", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((resp) => {
      cb(true);
    })
    .catch((e) => {
      // when the token does not show as authenticated
      // if the user logged in before, the auth data should be saved
      const username = getState().users.profile.email;
      const password = getState().users.profile.password;
      if (username && password)
        dispatch(
          authenticate({ username, password }, (t, successful) => {
            if (!successful) cb(false);
          })
        );
      // if the user data is not saved locally anyways
      else cb(false);
    });
};

export const authenticate = (data, cb) => async (dispatch, getState) => {
  // sync up with the API if the TOKEN is not working
  // expect to call this function many times
  // even without the user noticing (behind the scenes)

  users
    .post("authenticate", data)
    .then((resp) => {
      const token = resp.data.token;
      const profile = resp.data;

      localStorage.setItem("profile", JSON.stringify(profile));
      localStorage.setItem("token", token);

      dispatch({ type: TOKEN, payload: token });
      dispatch({ type: NEW_USER_PROFILE, payload: nameFieldsAutoCap(profile) });

      if (cb) cb(token, true);
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        cb(err.response.data.message, false);
      }
    });
};

export const updateUser = (data, cb) => async (dispatch, getState) => {
  const id = getState().users.profile.id;

  // the previous profile before being updated
  const profile = getState().users.profile;
  const token = getState().users.token;

  nameFieldsAutoCap(data);

  try {
    authenticated(users, token)
      .put(`/${id}`, data)
      .then((resp) => {
        console.log(resp);
        dispatch({ type: NEW_USER_PROFILE, payload: resp.data });
        localStorage.setItem("profile", JSON.stringify(resp.data));
        cb(true);
      });
  } catch (e) {
    if (e.response.status == 401) {
      console.log("authenticating user");
      dispatch(
        authenticate(
          {
            username: profile.username,
            password: profile.password,
          },
          (msg, successful) => {
            if (!successful) {
              console.log(msg);
              cb(false, "Login Again!");
            }
          }
        )
      );
    } else {
      cb(false, e);
    }
  }
};

export const registerUser = (data, cb) => async (dispatch, getState) => {
  nameFieldsAutoCap(data);

  // assign the backend required but not shown to frontend users values
  data.roles = "chess";
  data.origins = "ICEA Scholastic Chess";
  data.phonenumbers = "";
  data.address = "";
  data.socialmedia = {};
  data.usermeta = {};

  users
    .post("register", data)
    .then((response) => {
      dispatch(authenticate({ username: data.email, password: data.password }));

      cb(response, true);
    })
    .catch((error) => {
      console.log(error);
      cb("" + error, false);
    });
};
