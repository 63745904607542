import EditIcon from "@mui/icons-material/Edit";
import { IonContent, IonGrid, IonPage, IonTitle } from "@ionic/react";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import './styles/AboutUs.css'
import { TextField, FormControl, Button, InputAdornment } from '@mui/material';

const AboutUs = () => {
    return (
        <IonPage>
            <div className="body">
                <div className="body-nice">
                    <div className="NiceToMeetYou">
                        <strong>Nice To Meet You!!</strong>
                        <div className="IntroP">
                            We are a 501(c)(3) non-profit organization dedicated to preparing all school-aged children to live in a rapidly changing multicultural society. We do this through kids’ incubation programs, enrichment programs, culture events, charity activities and provide volunteer opportunities for kids and their families.
                        </div>
                    </div>
                </div>
                <div className="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                    </svg>
                </div>

                <div className="OurMission">
                    <strong>Our Mission</strong>
                    <div className="MissionP">
                        Our mission is to help families raise their children who value and pursue leadership ability, vibrant and positive personalities, self confidence, and academic excellence. To support those goals, we provide services,  both in-person and online.
                    </div>
                </div>
            </div>
        </IonPage>
    );
};
export default AboutUs;